.back-groundpr {
    height: 60vh;
    background:
    url('../Images/Frame1.png');   background-size: cover;
    background-repeat: no-repeat;
    
    
    
}

.h2editpr {
font-size: clamp(29px, 4vw, 44px);
font-weight: 700;

}
.peditpr {
font-size: clamp(20px, 5vw, 16px);
font-weight: 400;
margin-bottom: 0px !important;
;

}
.home-sectionpr{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .arr {
   margin-top: 40px;
   
    }
  }




  .card.flip {
    position: relative;
    min-height: 500px;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .card.flip .card-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .card.flip .front, .card.flip .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card.flip .back {
    transform: rotateY(180deg);
  }
  
  .card.flip:hover .card-inner {
    transform: rotateY(180deg);
  }
  
.rww {
    display: flex;
    align-items: center;
}
.py {
    display: flex;
    justify-content: center;
}
.uy {
    color: #2563EB;

}
.card-text{
    font-weight: 600;
    font-size: smaller;
}
.rows{
    margin: 3rem;
}
.column{
    padding: .5rem 1.5rem;
}
.rs{
    height: 100%;
    width: 100%;
}

 
.back-ground {
    height: 80vh;
    background:
    url('../Images/Frame1.png');   background-size: cover;
    background-repeat: no-repeat;
}

.h2edit {
font-size: clamp(29px, 4vw, 44px);
font-weight: 700;

}
.pedit {
font-size: clamp(16px, 5vw, 16px);
font-weight: 400;
margin-bottom: 0px !important;

}
.home-section{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
#green1 {
    border-radius: 5px !important;
    padding: 15px 32px !important;
    gap: 8px !important;
    background-color: white!important;
    color: black!important;
    border: 2px solid transparent; 
    margin-top: 40px !important;
    width: auto;
    font-weight: 600;
}
.imgphoto {
    width: 100%;
    height: 100%;
    max-width: 170px;
}
@media(max-width:575px){
    .rows{
        margin: 0px !important;
    }
}